import React from 'react';

interface ConditionalWrapProps {
    condition: boolean;
    wrap: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
}

const ConditionalWrap: React.FC<ConditionalWrapProps> = ({condition, children, wrap, ...props}) =>
    condition ? React.cloneElement(wrap(children), props) : children;

export default ConditionalWrap;
