import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {useNavigate} from 'react-router-dom';

interface CrumbLink {
    path: string;
}

interface GlobalState {
    navigateToCrumb: (idx: number) => void;
    setCrumbLinks: React.Dispatch<React.SetStateAction<CrumbLink[]>>;
    isNavDisabled: boolean;
    setNavDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    payorId: number | null;
    setPayorId: React.Dispatch<React.SetStateAction<number | null>>;
    planId: number | null;
    setPlanId: React.Dispatch<React.SetStateAction<number | null>>;
    siteId: number | null;
    setSiteId: React.Dispatch<React.SetStateAction<number | null>>;
}

interface ApplicationGlobalContextType {
    global: GlobalState;
}

const ApplicationGlobalContext = createContext<ApplicationGlobalContextType | undefined>(undefined);

export const useGlobalContext = (): GlobalState => {
    const context = useContext(ApplicationGlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within an ApplicationGlobalProvider');
    }
    return context.global;
};

const ApplicationGlobalProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const globalValue = useGlobalState();

    const contextValue = useMemo(
        () => ({
            global: globalValue,
        }),
        [globalValue]
    );

    return (
        <ApplicationGlobalContext.Provider value={contextValue}>
            {children}
        </ApplicationGlobalContext.Provider>
    );
};

const useGlobalState = (): GlobalState => {
    const navigate = useNavigate();
    const [isNavDisabled, setNavDisabled] = useState<boolean>(false);
    const [crumbLinks, setCrumbLinks] = useState<CrumbLink[]>([]);
    const [payorId, setPayorId] = useState<number | null>(null);
    const [planId, setPlanId] = useState<number | null>(null);
    const [siteId, setSiteId] = useState<number | null>(null);

    const navigateToCrumb = useCallback(
        (idx: number) => {
            const reversed = [...crumbLinks].reverse();
            if (reversed[idx]) {
                navigate(reversed[idx].path);
            }
        },
        [crumbLinks, navigate]
    );

    return useMemo(
        () => ({
            navigateToCrumb,
            setCrumbLinks,
            isNavDisabled,
            setNavDisabled,
            payorId,
            setPayorId,
            planId,
            setPlanId,
            siteId,
            setSiteId,
        }),
        [
            navigateToCrumb,
            setCrumbLinks,
            isNavDisabled,
            setNavDisabled,
            payorId,
            setPayorId,
            planId,
            setPlanId,
            siteId,
            setSiteId,
        ]
    );
};

export default ApplicationGlobalProvider;
