import lazy from 'react-lazy-with-preload';
/*
 * Routes will be lazy loaded when needed and preloaded on hover in the LeftNavDrawer
 */
import LoginComponent from './Login';
import UnauthorizedComponent from './Unauthorized';

const Users = lazy(() => import('./auth/applications/users/Users'));
const EditUser = lazy(() => import('./auth/applications/users/EditUser'));
const Applications = lazy(() => import('./auth/applications/Applications'));
const Groups = lazy(() => import('./auth/applications/groups/Groups'));
const EditGroup = lazy(() => import('./auth/applications/groups/EditGroup'));
const Roles = lazy(() => import('./auth/applications/roles/Roles'));
const EditRole = lazy(() => import('./auth/applications/roles/EditRole'));
const SpiUserManagement = lazy(() => import('./spi/users/UserManagementTable'));
const SpiManageUser = lazy(() => import('./spi/users/manage/ManageUser'));

const SpiSiteManagement = lazy(() => import('./spi/sites/SiteManagementTable'));
const SpiManageSite = lazy(() => import('./spi/sites/managesite/ManageSite'));
const SpiManageSitePayor = lazy(() => import('./spi/sites/ManageSitePayor'));
const SpiManageSitePlan = lazy(() => import('./spi/sites/plans/ManageSitePlan'));
const SpiSitePayorsManagement = lazy(() => import('./spi/sites/SitePayorsTable'));
const SpiSitePayorsPlansEdit = lazy(() => import('./spi/sites/SitePayorsPlansEdit'));

const SpiPayorManagement = lazy(() => import('./spi/payors/PayorManagementTable'));
const SpiManagePayor = lazy(() => import('./spi/payors/ManagePayor'));
const SpiPayorCreate = lazy(() => import('./spi/payors/manage/PayorCreate'));
const SpiPayorEdit = lazy(() => import('./spi/payors/manage/PayorEdit'));
const SpiPayorPlanCreate = lazy(() => import('./spi/payors/plans/manage/PlanCreate'));
const SpiPayorPlanEdit = lazy(() => import('./spi/payors/plans/manage/PlanEdit'));


export const RouteGroup = {
    AUTHORIZATION: 'Authorization',
    SPI: 'SPI',
};

export const RouteName = {
    AUTHORIZATION_USERS: 'Users',
    EDIT_USER: 'Edit User',
    AUTHORIZATION_APPLICATIONS: 'Applications',
    APPLICATIONS_GROUPS: 'Groups',
    EDIT_GROUP: 'Edit Group',
    APPLICATIONS_ROLES: 'Roles',
    EDIT_ROLE: 'Edit Role',
    SPI_USERS: 'User Management',
    SPI_MANAGE_USER: 'Manage User',
    SPI_SITES: 'Site Management',
    SPI_MANAGE_SITE: 'Manage Site',
    SPI_SITES_PAYORS: 'Manage Site Payors',
    SPI_SITES_PAYORS_PLANS_EDIT: 'Site Payors Plans Edit',
    SPI_MANAGE_SITE_PAYOR: 'Manage Site Payor',
    SPI_MANAGE_SITE_PLAN: 'Manage Site Plan',
    SPI_PAYORS: 'Payor Management',
    SPI_MANAGE_PAYOR: 'Manage Payor',
    SPI_PAYOR_CREATE: 'Payor Create',
    SPI_PAYOR_EDIT: 'Payor Edit',
    SPI_PAYOR_PLAN_CREATE: 'Payor Plan Create',
    SPI_PAYOR_PLAN_EDIT: 'Payor Plan Edit',
};

export const RoutePermissions = {
    SOME: 'SOME',
    ALL: 'ALL',
};

export const RouteMap = {
    [RouteName.AUTHORIZATION_USERS]: {
        group: RouteGroup.AUTHORIZATION,
        name: RouteName.AUTHORIZATION_USERS,
        priority: 1,
        component: Users,
        path: '/auth/users',
    },
    [RouteName.AUTHORIZATION_APPLICATIONS]: {
        group: RouteGroup.AUTHORIZATION,
        name: RouteName.AUTHORIZATION_APPLICATIONS,
        priority: 1,
        component: Applications,
        path: '/auth/applications',
    },
    [RouteName.SPI_USERS]: {
        group: RouteGroup.SPI,
        name: RouteName.SPI_USERS,
        priority: 1,
        component: SpiUserManagement,
        path: '/spi/users',
    },
    [RouteName.EDIT_USER]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/users/:databaseId',
        name: RouteName.EDIT_USER,
        priority: 2,
        component: EditUser,
    },
    [RouteName.APPLICATIONS_GROUPS]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/groups',
        name: RouteName.APPLICATIONS_GROUPS,
        priority: 2,
        component: Groups,
    },
    [RouteName.EDIT_GROUP]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/groups/:databaseId',
        name: RouteName.EDIT_GROUP,
        priority: 2,
        component: EditGroup,
    },
    [RouteName.APPLICATIONS_ROLES]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/roles',
        name: RouteName.APPLICATIONS_ROLES,
        priority: 2,
        component: Roles,
    },
    [RouteName.EDIT_ROLE]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/roles/:databaseId',
        name: RouteName.EDIT_ROLE,
        priority: 2,
        component: EditRole,
    },
    [RouteName.SPI_MANAGE_USER]: {
        group: RouteGroup.SPI,
        path: '/spi/users/:databaseId',
        name: RouteName.SPI_MANAGE_USER,
        priority: 2,
        component: SpiManageUser,
    },
    [RouteName.SPI_SITES]: {
        group: RouteGroup.SPI,
        name: RouteName.SPI_SITES,
        priority: 2,
        component: SpiSiteManagement,
        path: '/spi/sites',
    },
    [RouteName.SPI_MANAGE_SITE]: {
        group: RouteGroup.SPI,
        path: '/spi/sites/:siteId',
        name: RouteName.SPI_MANAGE_SITE,
        priority: 2,
        component: SpiManageSite,
    },
    [RouteName.SPI_MANAGE_SITE_PAYOR]: {
        group: RouteGroup.SPI,
        path: '/spi/sites/:siteId/payors/:payorId',
        name: RouteName.SPI_MANAGE_SITE_PAYOR,
        priority: 2,
        component: SpiManageSitePayor,
    },
    [RouteName.SPI_MANAGE_SITE_PLAN]: {
        group: RouteGroup.SPI,
        path: '/spi/sites/:siteId/payors/:payorId/plans/:planId',
        name: RouteName.SPI_MANAGE_SITE_PLAN,
        priority: 2,
        component: SpiManageSitePlan,
    },
    [RouteName.SPI_SITES_PAYORS]: {
        group: RouteGroup.SPI,
        path: '/spi/sites/:siteId/payors',
        name: RouteName.SPI_SITES_PAYORS,
        priority: 2,
        component: SpiSitePayorsManagement,
    },
    [RouteName.SPI_SITES_PAYORS_PLANS_EDIT]: {
        group: RouteGroup.SPI,
        path: '/spi/sites/:siteId/payors/edit',
        name: RouteName.SPI_SITES_PAYORS_PLANS_EDIT,
        priority: 2,
        component: SpiSitePayorsPlansEdit,
    },
    [RouteName.SPI_PAYORS]: {
        group: RouteGroup.SPI,
        name: RouteName.SPI_PAYORS,
        priority: 2,
        component: SpiPayorManagement,
        path: '/spi/payors',
    },
    [RouteName.SPI_MANAGE_PAYOR]: {
        group: RouteGroup.SPI,
        path: '/spi/payors/:payorId',
        name: RouteName.SPI_MANAGE_PAYOR,
        priority: 2,
        component: SpiManagePayor,
    },
    [RouteName.SPI_PAYOR_CREATE]: {
        group: RouteGroup.SPI,
        path: '/spi/payors/create',
        name: RouteName.SPI_PAYOR_CREATE,
        priority: 2,
        component: SpiPayorCreate,
    },
    [RouteName.SPI_PAYOR_EDIT]: {
        group: RouteGroup.SPI,
        path: '/spi/payors/:payorId/edit',
        name: RouteName.SPI_PAYOR_EDIT,
        priority: 2,
        component: SpiPayorEdit,
    },
    [RouteName.SPI_PAYOR_PLAN_CREATE]: {
        group: RouteGroup.SPI,
        path: '/spi/payors/:payorId/plans/create',
        name: RouteName.SPI_PAYOR_PLAN_CREATE,
        priority: 2,
        component: SpiPayorPlanCreate,
    },
    [RouteName.SPI_PAYOR_PLAN_EDIT]: {
        group: RouteGroup.SPI,
        path: '/spi/payors/:payorId/plans/:planId/edit',
        name: RouteName.SPI_PAYOR_PLAN_EDIT,
        priority: 2,
        component: SpiPayorPlanEdit,
    },
};

export const Login = {
    path: '/',
    component: LoginComponent,
};

export const Unauthorized = {
    path: '/unauthorized',
    component: UnauthorizedComponent,
};

export default Object.values(RouteMap);

const authUserDetail = (databaseId) => `/auth/users/${databaseId}`;
const authGroupDetail = (databaseId) => `/auth/groups/${databaseId}`;
const authRoleDetail = (databaseId) => `/auth/roles/${databaseId}`;
const spiUserDetail = (databaseId) => `/spi/users/${databaseId}`;

const spiSiteDetail = (siteId) => `/spi/sites/${siteId}`;
const spiSitePayors = (siteId) => `/spi/sites/${siteId}/payors`;
const spiSitePayor = (siteId, payorId) => `/spi/sites/${siteId}/payors/${payorId}`;
const spiSitePlan= (siteId, payorId, planId) => `/spi/sites/${siteId}/payors/${payorId}/plans/${planId}`;
const spiSitePayorsPlansEdit = (siteId) => `/spi/sites/${siteId}/payors/edit`;

const spiPayors = () => `/spi/payors`;
const spiPayorDetail = (payorId) => `/spi/payors/${payorId}`;
const spiPayorCreate = () => `/spi/payors/create`;
const spiPayorEdit = (payorId) => `/spi/payors/${payorId}/edit`;
const spiPayorPlanCreate = (payorId) => `/spi/payors/${payorId}/plans/create`;
const spiPayorPlanEdit = (payorId, planId) => `/spi/payors/${payorId}/plans/${planId}/edit`;

export const RouteBuilder = {
    authUserDetail,
    authGroupDetail,
    authRoleDetail,
    spiUserDetail,
    spiSiteDetail,
    spiSitePayors,
    spiSitePayor,
    spiSitePlan,
    spiSitePayorsPlansEdit,
    spiPayors,
    spiPayorDetail,
    spiPayorCreate,
    spiPayorEdit,
    spiPayorPlanCreate,
    spiPayorPlanEdit
};
