import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import {Collapse, darken, Tooltip, useMediaQuery} from '@mui/material';
import {lighten, useTheme} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {RouteGroup, RouteMap, RouteName} from './routes';
import AdminSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MedicationIcon from '@mui/icons-material/Medication';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShieldIcon from '@mui/icons-material/Shield';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import styled from '@emotion/styled';
import {dimensions} from '../constants/dimensions';
import ConditionalWrap from './ConditionalWrap';

const DrawerStyled = styled(Drawer)`
    .MuiDrawer-paper {
        top: 72px;
        height: calc(100% - 72px);
        width: ${dimensions.drawer.width.full}px;
        ${({theme}) => `${theme.breakpoints.down('sm')} {
          width: ${dimensions.drawer.width.mini}px
        }`}
        background-color: inherit;
    }
`;

const ListItemStyled = styled(ListItem)`
    border-bottom: 1px solid ${({theme}) => theme.palette.custom.gray[3]};
    cursor: pointer;

    & .MuiListItemIcon-root {
        min-width: 36px;
    }

    &.subItem {
        padding-left: 16px;

        &:hover {
            background-color: ${({theme}) => lighten(theme.palette.primary.main, 0.7)};
        }
    }

    &.subItemSelected {
        padding-left: 16px;
        border-left: 5px solid ${({theme}) => theme.palette.primary.main};
        background-color: ${({theme}) => lighten(theme.palette.primary.main, 0.2)};
        color: white;

        &:hover {
            background-color: ${({theme}) => lighten(theme.palette.primary.main, 0.3)};
        }
    }

    &.selected {
        border-left: 5px solid ${({theme}) => theme.palette.primary.main};
        background-color: ${({theme}) => theme.palette.custom.gray['1']};

        &:hover {
            background-color: ${({theme}) => darken(theme.palette.custom.gray['1'], 0.1)};
        }
    }
`;

const ExpandIconStyled = styled(ExpandMore)`
    transition: ${({theme}) =>
        theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        })};
    transform: rotate(0deg);

    &.rotate {
        transform: rotate(180deg);
    }
`;

const NavIconStyled = styled(ListItemIcon)`
    color: ${({theme}) => theme.palette.text.primary};
    background-color: inherit;

    &.subItemSelected {
        color: white;
    }
`;

const navItems = [
    {
        name: RouteGroup.AUTHORIZATION,
        Icon: AdminSettingsIcon,
        items: [
            {
                ...RouteMap[RouteName.AUTHORIZATION_USERS],
                Icon: ManageAccountsIcon,
            },
            {
                ...RouteMap[RouteName.APPLICATIONS_GROUPS],
                Icon: GroupsIcon,
            },
            {
                ...RouteMap[RouteName.APPLICATIONS_ROLES],
                Icon: FolderSharedIcon,
            },
        ],
    },
    {
        name: RouteGroup.SPI,
        Icon: MedicationIcon,
        items: [
            {
                ...RouteMap[RouteName.SPI_USERS],
                Icon: ManageAccountsIcon,
            },
            {
                ...RouteMap[RouteName.SPI_SITES],
                Icon: LocationCityIcon,
            },
            {
                ...RouteMap[RouteName.SPI_PAYORS],
                Icon: ShieldIcon,
            },
        ],
    },
];

export default function LeftNavDrawer({isDrawerOpen, permissions}) {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const theme = useTheme();
    const isFullSize = useMediaQuery(theme.breakpoints.up('sm'));
    const [openGroups, setOpenGroups] = useState([]);

    const hasPermissions = useCallback(
        (requiredPermissions) => {
            if (!requiredPermissions) return true;
            if (requiredPermissions.permissionsNeeded === 'ALL') {
                return requiredPermissions.permissionKeys.every((p) => permissions.includes(p));
            }
            if (requiredPermissions.permissionsNeeded === 'SOME') {
                return requiredPermissions.permissionKeys.some((p) => permissions.includes(p));
            }
            return false;
        },
        [permissions]
    );

    const filterForPermissions = useCallback(
        (link) => {
            const {items: childLinks, requiredPermissions, ...rest} = link;
            if (hasPermissions(requiredPermissions)) {
                const currLink = {...rest, items: []};
                childLinks?.forEach((childLink) => {
                    const permittedLink = filterForPermissions(childLink);
                    if (permittedLink) currLink.items.push(permittedLink);
                });
                return currLink;
            }
        },
        [hasPermissions]
    );

    const availableItems = useMemo(() => {
        return navItems
            .map(filterForPermissions)
            .filter(Boolean)
            .filter((i) => !i.items || i.items.length);
    }, [filterForPermissions]);

    useEffect(() => {
        const openGroups = availableItems
            .filter((i) => i.items && i.items.some((subItem) => pathname.startsWith(subItem.path)))
            .map((i) => i.name);
        setOpenGroups(openGroups);
    }, [availableItems, pathname]);

    const toggleOpenGroup = useCallback((e, isTopGroup) => {
        const groupTitle = e.currentTarget.getAttribute('value');
        setOpenGroups((prev) => {
            if (prev.includes(groupTitle)) {
                return isTopGroup ? [] : prev.filter((g) => g !== groupTitle);
            } else {
                return isTopGroup ? [groupTitle] : [...prev, groupTitle];
            }
        });
    }, []);

    const preloadRoute = useCallback(({preload, component}) => {
        component?.preload && component.preload();
        preload && preload();
    }, []);

    const handleRouteClick = useCallback(
        (e) => {
            const path = e.currentTarget.getAttribute('value');
            path && navigate(path);
        },
        [navigate]
    );

    const renderLinkItem = useCallback(
        ({exact, path, name, Icon, component, preload, depth = 2}) => {
            const selected = exact ? path === pathname : pathname.startsWith(path);
            let classNames = 'subItem ';
            if (selected) {
                classNames += 'subItemSelected';
            }

            return (
                <ListItemStyled
                    className={classNames.trim()}
                    key={path}
                    value={path}
                    onMouseOver={() => preloadRoute({preload, component})}
                    onClick={handleRouteClick}
                >
                    <ConditionalWrap
                        condition={!isFullSize}
                        wrap={(children) => (
                            <Tooltip title={name} enterDelay={500}>
                                {children}
                            </Tooltip>
                        )}
                    >
                        <NavIconStyled style={{paddingLeft: '15px'}} className={classNames.trim()}>
                            <Icon className={selected ? 'selected primary' : ''} />
                        </NavIconStyled>
                    </ConditionalWrap>
                    {isFullSize && <ListItemText primary={name} />}
                </ListItemStyled>
            );
        },
        [handleRouteClick, isFullSize, pathname, preloadRoute]
    );

    const renderGroupItem = useCallback(
        ({items, name, Icon, isTopGroup}) => {
            const open = openGroups.includes(name);
            const isSelected = (_items) =>
                _items?.some(({path, exact}) =>
                    exact ? path === pathname : pathname.startsWith(path)
                );

            const selected =
                isSelected(items) || items.some(({items: _items}) => isSelected(_items));

            return (
                <Fragment key={name}>
                    <ListItemStyled
                        value={name}
                        onClick={(e) => toggleOpenGroup(e, isTopGroup)}
                        className={selected ? 'selected' : ''}
                    >
                        <NavIconStyled>
                            <Icon />
                        </NavIconStyled>
                        {isFullSize && <ListItemText primary={name} />}
                        {items.length > 0 && <ExpandIconStyled className={open ? 'rotate' : ''} />}
                    </ListItemStyled>
                    <Collapse in={open}>
                        <List disablePadding>{items.map(renderLinkItem)}</List>
                    </Collapse>
                </Fragment>
            );
        },
        [toggleOpenGroup, openGroups, pathname, renderLinkItem, isFullSize]
    );

    return (
        <DrawerStyled variant="persistent" anchor="left" open={isDrawerOpen}>
            <List>
                {availableItems.map(({items, name, Icon}) =>
                    renderGroupItem({items, name, Icon, isTopGroup: true})
                )}
                <Divider />
            </List>
        </DrawerStyled>
    );
}

LeftNavDrawer.defaultProps = {
    permissions: [],
};

LeftNavDrawer.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
};
