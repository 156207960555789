import React from 'react';
import WebFontLoader from 'webfontloader';

import './i18n';
import './index.css';
import DarkModeThemeProvider from './context/ThemeContext';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import BootstrapConfigurations from './components/BootstrapConfigurations';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import LightTheme from './themes/light';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import axios from 'axios';
import {createRoot} from 'react-dom/client';

WebFontLoader.load({
    google: {
        families: ['Roboto:300,300i,400,400i,500,500i,700,700i'],
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: (count, error) => {
                if (
                    error &&
                    axios.isAxiosError(error) &&
                    [401, 403].includes(error?.response?.status)
                ) {
                    return false;
                }
                return count < 3;
            },
        },
    },
});

const renderApp = () => {
    const theme = createTheme(LightTheme);
    const root = createRoot(document.getElementById('root'));

    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <DarkModeThemeProvider>
                    <QueryClientProvider client={queryClient}>
                        <BootstrapConfigurations />
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </DarkModeThemeProvider>
            </ThemeProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
